;(($, window, document, undefined) => {
    $.extend(window.yunu, {
        data_editor(data, obj, batch){
            const tpl = `
                <form class="layui-form pt25 pb20">
                    ${data ? `<input type="hidden" name="id" value="${data.id}">` : ''}
                    <div class="layui-form-item">
                        <label class="layui-form-label">客户名称</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="name" value="${data?.name ?? ''}" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">联系人</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="contacts" value="${data?.contacts ?? ''}" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">手机号</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="tel" value="${data?.tel ?? ''}" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">公司电话</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="phone" value="${data?.phone ?? ''}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">微信</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="wx" value="${data?.wx ?? ''}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">QQ</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="qq" value="${data?.qq ?? ''}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">邮箱</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="email" value="${data?.email ?? ''}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">官网</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="domain" value="${data?.domain ?? ''}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">地址</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="address" value="${data?.address ?? ''}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">线索来源</label>
                        <div class="layui-input-inline w334">
                            <div id="xsly"></div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">合作项目</label>
                        <div class="layui-input-inline w334">
                            <div id="hzxm"></div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">客户状态</label>
                        <div class="layui-input-inline w334">
                            <input type="radio" name="type" value="0" title="普通客户" ${data?.type == 0 || !data ? 'checked' : ''} lay-verify="required">
                            <input type="radio" name="type" value="1" title="重点客户" ${data?.type == 1 ? 'checked' : ''} lay-verify="required">
                            <input type="radio" name="type" value="2" title="已签客户" ${data?.type == 2 ? 'checked' : ''} lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>
            `;

            layui.use(['layer', 'form', 'table'], function(){
                const layer = layui.layer;
                const form = layui.form;
                const table = layui.table;

                layer.open({
                    type: 1,
                    title: data ? '修改' : '新增',
                    area: ['566px'],
                    content: tpl,
                    success: function () {
                        form.render();
                        let xsly = xmSelect.render({
                            el: '#xsly',
                            name: 'xsly_id',
                            prop: {
                                name: 'name',
                                value: 'id',
                            },
                            layVerify: 'required',
                            radio: true,
                            filterable: true,
                            clickClose: true,
                            data: [],
                            initValue: [data?.xsly_id ?? localStorage.getItem('data_editor_xsly') ?? ''],
                        });
                        $.get('/' + module_name + '/FormSelect/xsly').then(res => {
                            xsly.update({
                                data: res.data,
                                autoRow: true,
                            })
                        });
                        let hzxm = xmSelect.render({
                            el: '#hzxm',
                            name: 'hzxm_id',
                            prop: {
                                name: 'name',
                                value: 'id',
                            },
                            layVerify: 'required',
                            radio: true,
                            filterable: true,
                            clickClose: true,
                            data: [],
                            initValue: [data?.hzxm_id ?? localStorage.getItem('data_editor_hzxm') ?? ''],
                        });
                        $.get('/' + module_name + '/FormSelect/hzxm').then(res => {
                            hzxm.update({
                                data: res.data,
                                autoRow: true,
                            })
                        });
                        form.on('submit(*)', function({ field }){
                            if (batch) {
                                field['batch'] = 1;
                            }
                            localStorage.setItem('data_editor_xsly', field.xsly_id);
                            localStorage.setItem('data_editor_hzxm', field.hzxm_id);
                            yunu.post({
                                url: '/' + module_name + '/Data/editor',
                                field,
                                success: function (res) {
                                    layer.closeAll('page');
                                    yunu.msg(res.info);
                                    table.reload('table');
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        receive(data, obj, batch){
            const tpl = `
                <form class="layui-form pt25 pb20">
                    ${data ? `<input type="hidden" name="clueid" value="${data.id}">` : ''}
                    <div class="layui-form-item">
                        <label class="layui-form-label">客户名称</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="name" value="${data?.title ?? ''}" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">联系人</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="contacts" value="${data?.title ?? ''}" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">手机号</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="tel" value="${data?.tel ?? ''}" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">公司电话</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="phone" value="">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">微信</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="wx" value="">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">QQ</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="qq" value="">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">邮箱</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="email" value="">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">官网</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="domain" value="">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">地址</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="address" value="">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">线索来源</label>
                        <div class="layui-input-inline w334">
                            <div id="xsly"></div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">合作项目</label>
                        <div class="layui-input-inline w334">
                            <div id="hzxm"></div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">客户状态</label>
                        <div class="layui-input-inline w334">
                            <input type="radio" name="type" value="0" title="普通客户" checked lay-verify="required">
                            <input type="radio" name="type" value="1" title="重点客户" lay-verify="required">
                            <input type="radio" name="type" value="2" title="已签客户" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>
            `;

            layui.use(['layer', 'form', 'table'], function(){
                const layer = layui.layer;
                const form = layui.form;
                const table = layui.table;

                layer.open({
                    type: 1,
                    title: data ? '修改' : '新增',
                    area: ['566px'],
                    content: tpl,
                    success: function () {
                        form.render();
                        let xsly = xmSelect.render({
                            el: '#xsly',
                            name: 'xsly_id',
                            prop: {
                                name: 'name',
                                value: 'id',
                            },
                            layVerify: 'required',
                            radio: true,
                            filterable: true,
                            clickClose: true,
                            data: [],
                            initValue: [data?.xsly_id ?? localStorage.getItem('data_editor_xsly') ?? ''],
                        });
                        $.get('/' + module_name + '/FormSelect/xsly').then(res => {
                            xsly.update({
                                data: res.data,
                                autoRow: true,
                            })
                        });
                        let hzxm = xmSelect.render({
                            el: '#hzxm',
                            name: 'hzxm_id',
                            prop: {
                                name: 'name',
                                value: 'id',
                            },
                            layVerify: 'required',
                            radio: true,
                            filterable: true,
                            clickClose: true,
                            data: [],
                            initValue: [data?.hzxm_id ?? localStorage.getItem('data_editor_hzxm') ?? ''],
                        });
                        $.get('/' + module_name + '/FormSelect/hzxm').then(res => {
                            hzxm.update({
                                data: res.data,
                                autoRow: true,
                            })
                        });
                        form.on('submit(*)', function({ field }){
                            if (batch) {
                                field['batch'] = 1;
                            }
                            localStorage.setItem('data_editor_xsly', field.xsly_id);
                            localStorage.setItem('data_editor_hzxm', field.hzxm_id);
                            yunu.post({
                                url: '/' + module_name + '/Dyclue/batch',
                                field,
                                success: function (res) {
                                    layer.closeAll('page');
                                    yunu.msg(res.info);
                                    table.reload('table');
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        bdreceive(data, obj, batch){
            const tpl = `
                <form class="layui-form pt25 pb20">
                    ${data ? `<input type="hidden" name="clueid" value="${data.id}">` : ''}
                    <div class="layui-form-item">
                        <label class="layui-form-label">客户名称</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="name" value="${data?.title ?? ''}" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">联系人</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="contacts" value="${data?.title ?? ''}" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">手机号</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="tel" value="${data?.phone ?? ''}" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">公司电话</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="phone" value="">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">微信</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="wx" value="">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">QQ</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="qq" value="">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">邮箱</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="email" value="">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">官网</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="domain" value="">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">地址</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="address" value="">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">线索来源</label>
                        <div class="layui-input-inline w334">
                            <div id="xsly"></div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">合作项目</label>
                        <div class="layui-input-inline w334">
                            <div id="hzxm"></div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">客户状态</label>
                        <div class="layui-input-inline w334">
                            <input type="radio" name="type" value="0" title="普通客户" checked lay-verify="required">
                            <input type="radio" name="type" value="1" title="重点客户" lay-verify="required">
                            <input type="radio" name="type" value="2" title="已签客户" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>
            `;

            layui.use(['layer', 'form', 'table'], function(){
                const layer = layui.layer;
                const form = layui.form;
                const table = layui.table;

                layer.open({
                    type: 1,
                    title: data ? '修改' : '新增',
                    area: ['566px'],
                    content: tpl,
                    success: function () {
                        form.render();
                        let xsly = xmSelect.render({
                            el: '#xsly',
                            name: 'xsly_id',
                            prop: {
                                name: 'name',
                                value: 'id',
                            },
                            layVerify: 'required',
                            radio: true,
                            filterable: true,
                            clickClose: true,
                            data: [],
                            initValue: [data?.xsly_id ?? localStorage.getItem('data_editor_xsly') ?? ''],
                        });
                        $.get('/' + module_name + '/FormSelect/xsly').then(res => {
                            xsly.update({
                                data: res.data,
                                autoRow: true,
                            })
                        });
                        let hzxm = xmSelect.render({
                            el: '#hzxm',
                            name: 'hzxm_id',
                            prop: {
                                name: 'name',
                                value: 'id',
                            },
                            layVerify: 'required',
                            radio: true,
                            filterable: true,
                            clickClose: true,
                            data: [],
                            initValue: [data?.hzxm_id ?? localStorage.getItem('data_editor_hzxm') ?? ''],
                        });
                        $.get('/' + module_name + '/FormSelect/hzxm').then(res => {
                            hzxm.update({
                                data: res.data,
                                autoRow: true,
                            })
                        });
                        form.on('submit(*)', function({ field }){
                            if (batch) {
                                field['batch'] = 1;
                            }
                            localStorage.setItem('data_editor_xsly', field.xsly_id);
                            localStorage.setItem('data_editor_hzxm', field.hzxm_id);
                            yunu.post({
                                url: '/' + module_name + '/Bdclue/batch',
                                field,
                                success: function (res) {
                                    layer.closeAll('page');
                                    yunu.msg(res.info);
                                    table.reload('table');
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        data_setting(data, obj){
            const tpl = `
                <form class="layui-form pt25 pb20">
                    ${data ? `<input type="hidden" name="id" value="${data.id}">` : ''}
                    <div class="layui-form-item">
                        <label class="layui-form-label">下次跟进时间</label>
                        <div class="layui-input-inline w334">
                            <input type="text" class="layui-input" name="next_record_time" id="next_record_time" value="${data?.next_record_time ?? ''}" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>
            `;

            layui.use(['layer', 'form', 'table', 'laydate'], function(){
                const layer = layui.layer;
                const form = layui.form;
                const table = layui.table;
                const laydate = layui.laydate;

                layer.open({
                    type: 1,
                    area: ['566px'],
                    content: tpl,
                    success: function () {
                        form.render();
                        laydate.render({
                            elem: '#next_record_time'
                            ,type: 'datetime'
                        });
                        form.on('submit(*)', function({ field }){
                            yunu.post({
                                url: '/' + module_name + '/Data/editor',
                                field: {
                                    editor_type: 'data_setting',
                                    ...field
                                },
                                success: function (res) {
                                    layer.closeAll('page');
                                    yunu.msg(res.info);
                                    table.reload('table');
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        record(data, obj){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    title: '总数据',
                    area: ['1200px', '60%'],
                    content: `<div class="pd20">
                        <div class="form_list clearfix">
                            <div class="layui-inline">
                                <a class="layui-btn btn" id="record_editor" href="javascript:;">新增</a>
                            </div>
                        </div>
                        <div class="table_record" id="table_record" lay-filter="table_record"></div>
                    </div>`,
                    success: function (layero, index) {
                        $('#record_editor').on('click', function () {
                            yunu.record_editor(null, null, data.id);
                        });
                        yunu.table({
                            elem: '#table_record',
                            skin: 'mult',
                            url: '/' + module_name + '/record/index',
                            where: {id: data.id},
                            cols: [[
                                {field: 'index', title: '序号', type: 'numbers', width: 60, sort: true}
                                ,{field: 'content', title: '跟进内容', align: 'center'}
                                ,{field: 'update_time', title: '跟进时间', align: 'center', width: 220}
                                ,{field: 'username', title: '跟进人', align: 'center', width: 100}
                                ,{title: '操作', align: 'center', width: 100, templet: function (d) {
                                    return '<a class="table_btn" href="javascript:;" lay-event="edit">修改</a>';
                                }}
                            ]],
                        }, {
                            table: 'table_record',
                            event: {
                                edit: function (data, obj) {
                                    yunu.record_editor(data, obj);
                                },
                                del: function (data, obj) {
                                    layer.confirm('您是否确认删除？', function(index){
                                        yunu.post({
                                            url: '/' + module_name + '/record/del',
                                            field: {id: data.id},
                                            success: function (res) {
                                                yunu.msg(res.info);
                                                table.reload('table_record');
                                            }
                                        });
                                        layer.close(index);
                                    });
                                },
                            }
                        });
                    }
                })
            });
        },
        record_editor(data, obj, data_id){
            const tpl = `<form class="layui-form pt25 pb20">
                ${data ? `<input type="hidden" name="id" value="${data.id}">` : ''}
                ${data_id ? `<input type="hidden" name="data_id" value="${data_id}">` : ''}
                <div class="layui-form-item">
                    <label class="layui-form-label">跟进内容</label>
                    <div class="layui-input-block mr50">
                        <textarea class="layui-textarea" name="content">${data?.content ?? ''}</textarea>
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    title: data ? '修改' : '新增',
                    area: ['566px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();

                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/record/editor',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    if (typeof callback === 'function') {
                                        yunu.msg(res.info);
                                        callback();
                                    } else {
                                        yunu.msg(res.info, {
                                            time: 1000,
                                            end: function () {
                                                table.reload('table_record');
                                            }
                                        });
                                    }
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        transfer(callback){
            const tpl = `
                <form class="layui-form pt25 pb20">
                    <div class="layui-form-item">
                        <label class="layui-form-label">用户</label>
                        <div class="layui-input-inline w334">
                            <div id="user"></div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>
            `;

            layui.use(['layer', 'form', 'table'], function(){
                const layer = layui.layer;
                const form = layui.form;
                const table = layui.table;

                layer.open({
                    type: 1,
                    title: '转让',
                    area: ['566px', '400px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        let xsly = xmSelect.render({
                            el: '#user',
                            name: 'user',
                            prop: {
                                name: 'username',
                                value: 'id',
                            },
                            layVerify: 'required',
                            radio: true,
                            filterable: true,
                            clickClose: true,
                            data: [],
                        });
                        $.get('/' + module_name + '/FormSelect/user').then(res => {
                            xsly.update({
                                data: res.data,
                                autoRow: true,
                            })
                        });
                        form.on('submit(*)', function({ field }){
                            callback(field.user, index);
                            return false;
                        });
                    }
                });
            });
        },
    });

    $(() => {

    });

})(jQuery, window, document);
